<template>
  <div>
    <card v-if="currentUser">
      <div class="row">
        <div class="col-sm-10">
          <h3><span class="font-weight-bold">About Me: </span><el-button type="primary" class="float-right" @click="editUser" icon="el-icon-edit"></el-button></h3>
          <div class="row">
            <div class="col-sm-6">
              <h5><span class="font-weight-bold">Full Name: </span>{{currentUser.first_name}} {{currentUser.last_name}}</h5>
              <h5><span class="font-weight-bold">Age: </span>{{currentUser.age}}</h5>
              <h5 v-if="userRank"><span class="font-weight-bold">Rank: </span>{{userRank.name}}</h5>
            </div>
            <div class="col-sm-6">
              <h5><span class="font-weight-bold">Weight: </span>{{currentUser.weight}}</h5>
              <h5><span class="font-weight-bold">Weight Class: </span>{{}}</h5>
              <h5><span class="font-weight-bold">Average Board Size: </span>{{}}</h5>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <img :src="currentUser.img_url" alt="Profile Picture">
        </div>
      </div>
    </card>
    <card>
      <div class="row" v-if="checkIfJudge()">
        <div class="col-sm-4 text-center">
          <h4 class="font-weight-bold">My TKD Curriculum</h4>
          <el-button type="primary" @click="goToCurriculum" class="margin">Current Curriculum</el-button>
          <br>
          <el-button type="primary" @click="goToTestingSheet" class="margin">Testing Sheet Preview</el-button>
          <br>
          <el-button type="primary" @click="goToCertificates" class="margin">My TKD Certificates</el-button>
        </div>
        <div class="col-sm-4 text-center">
          <h4 class="font-weight-bold">My Breaking</h4>
          <el-button type="primary" @click="goToEvents" class="margin">My Events</el-button>
          <br>
          <el-button type="primary" @click="goToOutlines" class="margin">My Breaking Outlines</el-button>
          <br>
          <el-button type="primary" @click="goToScores" class="margin">My Presentation Scores</el-button>
        </div>
        <div class="col-sm-4 text-center">
          <h4 class="font-weight-bold">Judging</h4>
          <el-button type="primary" @click="goToJudging" class="margin">Judge an Outline</el-button>
          <br>
          <el-button type="primary" @click="goToJudgingEvent" class="margin">Judge for an Event</el-button>
          <br>
          <el-button type="primary" @click="goToJudgingSheet" class="margin">Blank Judging Sheet PDF</el-button>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-6 text-center">
          <h4 class="font-weight-bold">My TKD Curriculum</h4>
          <el-button type="primary" @click="goToCurriculum" class="margin">Current Curriculum</el-button>
          <br>
          <el-button type="primary" @click="goToTestingSheet" class="margin">Testing Sheet Preview</el-button>
          <br>
          <el-button type="primary" @click="goToCertificates" class="margin">My TKD Certificates</el-button>
        </div>
        <div class="col-sm-6 text-center">
          <h4 class="font-weight-bold">My Breaking</h4>
          <el-button type="primary" @click="goToEvents" class="margin">My Events</el-button>
          <br>
          <el-button type="primary" @click="goToOutlines" class="margin">My Breaking Outlines</el-button>
          <br>
          <el-button type="primary" @click="goToScores" class="margin">My Presentation Scores</el-button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Edit"
      :visible.sync="modals.edit">
      <div class="text-center" v-if="tempUser">
        <div class="row">
          <div class="col-sm-3">
            <label>First Name:</label>
            <br>
            <el-input v-model="tempUser.first_name"></el-input>
          </div>
          <div class="col-sm-3">
            <label>Last Name:</label>
            <br>
            <el-input v-model="tempUser.last_name"></el-input>
          </div>
          <div class="col-sm-3">
            <label>Age:</label>
            <br>
            <el-input-number v-model="tempUser.age"></el-input-number>
          </div>
          <div class="col-sm-3">
            <label>Weight:</label>
            <br>
            <el-input-number v-model="tempUser.weight"></el-input-number>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="saveUser" icon="">Save</el-button>
          <el-button type="primary" @click="closeModal('edit')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import EventsAPIService from 'src/servicehandlers/EventsAPIService';
import OutlinesAPIService from 'src/servicehandlers/OutlinesAPIService';
import UserAPIService from "../../../servicehandlers/UsersAPIService";
import RanksAPIService from "../../../servicehandlers/RanksAPIService";
import moment from 'moment';
import { Table, TableColumn, Dialog, Button, Input, InputNumber } from 'element-ui';

const eventAPIService = new EventsAPIService();
const outlineAPIService = new OutlinesAPIService();
const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
  },
  data() {
    return {
      eventData: [],
      eventColumns: ['Name','Description','Link','Start Date','End Date'],
      outlineColumns: ['Order','Description','Edit','Delete'],
      editTooltip: 'Edit Task',
      deleteTooltip: 'Remove',
      outlines: [],
      pieChart: {
        data: {
          labels: ['40%', '20%', '40%'],
          series: [40, 20, 40]
        }
      },
      lineChart: {
        data: {
          labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
          series: [
            [287, 385, 490, 492, 554, 586, 698, 695],
            [67, 152, 143, 240, 287, 335, 435, 437],
            [23, 113, 67, 108, 190, 239, 307, 308]
          ]
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      barChart: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
          ]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc(value) {
                return value[0]
              }
            }
          }]
        ]
      },
      tableData: {
        data: [
          {
            title: 'Sign contract for "What are conference organizers afraid of?"',
            checked: false
          },
          {
            title: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
            checked: true
          },
          {
            title: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            checked: true
          },
          { title: 'Create 4 Invisible User Experiences you Never Knew About', checked: false },
          { title: 'Read "Following makes Medium better"', checked: false },
          { title: 'Unfollow 5 enemies from twitter', checked: false }
        ]
      },
      currentUser: null,
      scopes: [],
      ranks: [],
      userRank: null,
      modals: {
        error: false,
        success: false,
        edit: false,
      },
      tempUser: null,
      error_txt: '',
      success_txt: '',
    }
  },
  mounted() {
    this.getCurrentUser()
  },
  methods: {
    getCurrentUser(){
      let userID = this.$store.getters.userId
      return usersAPIService.getUserById(userID, this.$router)
        .then((response) => {
          this.currentUser = response
          if (!this.currentUser.img_url){
            this.currentUser.img_url = '/static/img/faces/face-0.jpg'
          }
          this.scopes = this.$store.getters.scopes
          this.getRanks()
        })
        .catch((e) => {
          this.error_txt = 'Error getting current user: ' + e
          this.openModal('error')
        })
    },
    getRanks(){
      return ranksAPIService.getRanks(this.$router)
        .then((response) => {
          this.ranks = response
          for (let rank of this.ranks){
            if (rank.id === this.currentUser.rank_id){
              this.userRank = rank
              break
            }
          }
        })
        .catch((e) => {
          this.error_txt = 'Error getting ranks: ' + e
          this.openModal('error')
        })
    },
    editUser(){
      this.tempUser = JSON.parse(JSON.stringify(this.currentUser))
      this.openModal('edit')
    },
    saveUser(){
      return usersAPIService.updateUser(this.currentUser.id, this.tempUser, this.$router)
        .then(() => {
          this.success_txt = 'Successfully Saved User'
          this.openModal('success')
          this.closeModal('edit')
          this.getCurrentUser()
        })
        .catch((e) => {
          this.error_txt = 'Error while updating user: ' + e
          this.openModal('error')
        })
    },
    goToCurriculum(){
      this.$router.push({
        name: 'Print Details'
      });
    },
    goToTestingSheet(){
      this.$router.push({
        name: 'Rank Checklist'
      });
    },
    goToCertificates(){
      /*this.$router.push({
        name: 'rank-checklist'
      });*/
    },
    goToEvents(){
      this.$router.push({
        name: 'Events'
      });
    },
    goToOutlines(){
      this.$router.push({
        name: 'My Outlines'
      });
    },
    goToScores(){
      this.$router.push({
        name: 'Judge View Page'
      });
    },
    goToJudging(){
      this.$router.push({
        name: 'Judging Page'
      });
    },
    goToJudgingEvent(){
      /*this.$router.push({
        name: 'Judging Page'
      });*/
    },
    goToJudgingSheet(){
      /*this.$router.push({
        name: 'Judging Page'
      });*/
    },
    checkIfJudge(){
      for (let scope of this.scopes){
        if (scope.name === 'judge-page'){
          return true
        }
      }
      return false
    },
    handleSortChangeEvents({ column, prop, order}) {
      if (order === "ascending") {
        this.eventData.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.eventData.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    handleSortChangeOutlines({ column, prop, order}) {
      if (order === "ascending") {
        this.outlines.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.outlines.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
      if (name === 'view') this.kicksToView = null
    },
  }
}
</script>
<style scoped>
.margin{
  margin: 6px;
}
</style>
