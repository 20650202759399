<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Step Sparring</h4>
                <p class="card-category">
                  <el-button type="primary" @click="createStepSparring">Create New Step Sparring</el-button>
                  <el-button type="primary" @click="openModal('past')" v-if="allSparrings.length > 1">Past Step Sparrings</el-button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentStepSparring">
        <div class="row">
          <div class="col-sm-12 text-center">
            <label>Name</label>
            <br>
            <el-input v-model="currentStepSparring.name"  @change="updateStepSparring(currentStepSparring.id)" style="max-width: 50%"></el-input>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12 text-center">
            <label>Description</label>
            <br>
            <el-input type="textarea" :autosize="{ minRows: 20, maxRows: 30 }" style="width: 80%"  v-model="currentStepSparring.description" @change="updateStepSparring(currentStepSparring.id)"></el-input>
          </div>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Description"
      :visible.sync="modals.dis">
      <div class="text-center" v-if="selectedSparring">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 30}"
          placeholder="Please input"
          v-model="selectedSparring.description">
        </el-input>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="updateStepSparring(selectedSparring.id)" icon="">Save</el-button>
          <el-button type="danger" @click="closeModal('dis')" icon="">Canel</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Past Step Sparrings"
      :visible.sync="modals.past">
      <div class="text-center">
        <div class="row align-items-center">
          <div class="col-sm-2"></div>
          <div class="col-sm-6">
            <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterSparrings"></el-input>
          </div>
          <div class="col-sm-4" style="padding-left: 5px;">
            <el-button type="primary" @click="filterSparrings" icon="">Search</el-button>
          </div>
        </div>
        <el-table stripe :data="filteredAllSparrings" style="width: 100%" height="50vh"  @sort-change="handleSortChange">
          <el-table-column prop="name" sortable="custom" label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              <el-input v-model="scope.row.name" @change="updateStepSparring(scope.row.id)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Description" :min-width="150" align="center">
            <template v-slot="scope">
              <el-button type="primary" @click="displayDescription(scope.row)">Edit Description</el-button>
            </template>
          </el-table-column>
          <el-table-column label="Date Created" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.createdAt.toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}}
            </template>
          </el-table-column>
          <el-table-column label="Last Updated" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.updatedAt.toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}}
            </template>
          </el-table-column>
          <el-table-column label="Make Current" :min-width="150" align="center">
            <template v-slot="scope">
              <div class="flex-row">
                <el-button type="primary" @click="makeCurrent(scope.row.id)" icon="">Make Current</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template v-slot="scope">
              <div class="flex-row">
                <el-button type="danger" @click="deleteStepSparring(scope.row.id)" icon="el-icon-delete"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('past')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Select, Input, Button, Checkbox, Table, TableColumn } from 'element-ui';
import StepSparringAPIService from "../../../../servicehandlers/StepSparringAPIService";

const stepSparringAPIService = new StepSparringAPIService()

export default {
  name: "StepSparring",
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      spinning: false,
      modals: {
        error: false,
        success: false,
        past: false,
        dis: false,
      },
      error_txt: '',
      success_txt: '',
      allSparrings: [],
      filteredAllSparrings: [],
      currentStepSparring: null,
      search: '',
      selectedSparring: null,
      discription: '',
    }
  },
  mounted() {
    this.getStepSparrings()
  },
  methods: {
    getStepSparrings(){
      this.spinning = true
      return stepSparringAPIService.getStepSparrings(this.$router)
        .then((response) => {
          this.allSparrings = []
          this.filteredAllSparrings = []
          this.allSparrings = response
          for (let step of this.allSparrings){
            step.createdAt = new Date(step.createdAt)
            step.updatedAt = new Date(step.updatedAt)
            if (step.current){
              this.currentStepSparring = step
            } else {
              this.filteredAllSparrings.push(step)
            }
          }
          if (this.allSparrings.length === 1){
            this.closeModal('past')
          }
          this.spinning = false
        })
        .catch((e) => {
          this.error_txt = 'Error getting Step Sparrings: ' + e
          this.openModal('error')
          this.spinning = false
        })
    },
    createStepSparring(){
      let temp = {
        name: '',
        description: '',
        current: true,
      }
      for (let step of this.allSparrings){
        if (step.current){
          step.current = false
          this.updateStepSparring(step.id)
        }
      }
      this.spinning = true
      return stepSparringAPIService.addStepSparring(temp, this.$router)
        .then(() => {
          this.success_txt = "Successfully added Step Sparring"
          this.openModal('success')
          this.spinning = false
          this.getStepSparrings()
        })
        .catch((e) => {
          this.error_txt = "Error while adding Step Sparring: " + e
          this.openModal('error')
          this.spinning = false
        })
    },
    updateStepSparring(stepID){
      this.spinning = true
      let toBeUpdated = null
      for (let step of this.allSparrings){
        if (step.id === stepID){
          toBeUpdated = step
          break
        }
      }
      return stepSparringAPIService.updateStepSparring(stepID, toBeUpdated, this.$router)
        .then(() => {
          //this.success_txt = 'Successfully updated Step Sparring'
          //this.openModal('success')
          this.spinning = false
          if (this.modals.dis){
            this.modals.dis = false
          }
          this.getStepSparrings()
        })
        .catch((e) => {
          this.error_txt = "Error while updating Step Sparring: " + e
          this.openModal('error')
          this.spinning = false
        })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredOrders.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredOrders.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterSparrings(){
      this.filteredAllSparrings = this.allSparrings.filter(e => {return (!e.current)}).filter(
        (data) =>
          !this.search ||
          (data.name.toLowerCase() + data.name.toLowerCase()).includes(this.search.toLowerCase())
      )
    },
    makeCurrent(id){
      for (let step of this.allSparrings){
        if (step.id === id){
          step.current = true
          this.updateStepSparring(id)
        } else if (step.current){
          step.current = false
          this.updateStepSparring(step.id)
        }
      }
      this.getStepSparrings()
      this.closeModal('past')
    },
    deleteStepSparring(id){
      this.spinning = true
      return stepSparringAPIService.deleteStepSparring(id, this.$router)
        .then(() => {
          this.success_txt = 'Successfully Deleted Step Sparring'
          this.openModal('success')
          this.spinning = false
          this.getStepSparrings()
        })
        .catch((e) => {
          this.error_txt = 'Error while deleting Step Sparring: ' + e
          this.openModal('error')
          this.spinning = false
        })
    },
    displayDescription(step){
      this.selectedSparring = step
      this.openModal('dis')
    },
  }
}
</script>

<style scoped>
.customHeader{
  position: sticky;
  top: 10px;
  background-color: #f0f0f0;
  z-index: 100;
  padding: 1em;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  align-content: center;
}
.customFooter{
  background-color: #f0f0f0;
  border-radius: 6px;
  font-size: 16px;
  padding: 15px;
}
.customContainer{
  padding: 1em;
}
.customCard {
  padding: .5em;
  border-radius: 6px;
}
.customCard:hover{
  background-color: #f0f0f0;
}
.moveUp{
  animation: moveUp .5s ease;
}
.moveDown{
  animation: moveDown .5s ease;
}

el-input,el-select{
  width: 100%;
}

@keyframes moveUp {
  0%{
    transform: translate(0, 65px);
  }
  100%{
    transform: translate(0);
  }
}

@keyframes moveDown {
  0%{
    transform: translate(0, -65px);
  }
  100%{
    transform: translate(0);
  }
}
</style>
