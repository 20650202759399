<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-3">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Events Table</h4>
                <p class="card-category">
                  Create or Edit Events
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="addNewEvent" icon="">Add New Event</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-5 pl-1">
            <label>Show Only:</label>
            <br>
            <el-checkbox-button v-for="type in eventTypes" v-bind:key="type.id" :value="type.value" @change="filterEvents(type.id)" style="border-left: lightgray solid 1px;border-radius: 5px">{{type.name}}</el-checkbox-button>
            <el-switch style="float: right" inactive-text="Show Archived Events" v-model="showArchived" @change="filterEvents"></el-switch>
          </div>
          <div class="col-sm-4">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterEvents"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterEvents" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredEvents" style="width: 100%" height="55vh" @sort-change="handleSortChange">
        <el-table-column prop="id" sortable="custom" label="ID" :min-width="100" align="center">
          <template v-slot="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input v-if="!scope.row.locked" v-model="scope.row.name" placeholder="Name" @change="saveEvent(scope.row)"></el-input>
            <el-input v-else v-model="scope.row.name" placeholder="Name" @change="saveEvent(scope.row)" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Description" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input v-if="!scope.row.locked" v-model="scope.row.description" placeholder="Description" @change="saveEvent(scope.row)"></el-input>
            <el-input v-else v-model="scope.row.description" placeholder="Description" @change="saveEvent(scope.row)" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Event Type" :min-width="200" align="center">
          <template v-slot="scope">
            <el-select filterable v-if="!scope.row.locked" v-model="scope.row.event_type_id" placeholder="Type" style="width: 100%"
                       @change="saveEvent(scope.row)">
              <el-option v-for="item in eventTypes"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <el-select filterable v-else v-model="scope.row.event_type_id" placeholder="Type" style="width: 100%"
                       @change="saveEvent(scope.row)" disabled>
              <el-option v-for="item in eventTypes"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="start_date" sortable="custom" label="Start Date" :min-width="175" align="center">
          <template v-slot="scope">
            <el-date-picker v-if="!scope.row.locked" v-model="scope.row.start_date" type="date" placeholder="Start Date" size="large" @change="saveEvent(scope.row)"></el-date-picker>
            <el-date-picker v-else v-model="scope.row.start_date" type="date" placeholder="Start Date" size="large" disabled></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column prop="end_date" sortable="custom" label="End Date" :min-width="175" align="center">
          <template v-slot="scope">
            <el-date-picker v-if="!scope.row.locked" v-model="scope.row.end_date" type="date" placeholder="End Date" size="large" @change="saveEvent(scope.row)"></el-date-picker>
            <el-date-picker v-else v-model="scope.row.end_date" type="date" placeholder="End Date" size="large" disabled></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="Actions" :min-width="320" align="center">
          <template v-slot="scope">
            <el-button type="primary" @click="manageEvent(scope.row)" icon="">Manage</el-button>
            <el-button type="info" @click="archiveEvent(scope.row)" icon="" v-if="!scope.row.archived">Archive</el-button>
            <el-button type="info" @click="archiveEvent(scope.row)" icon="" v-else>Unarchive</el-button>
            <el-button v-if="!scope.row.locked" type="warning" @click="lockEvent(scope.row.id)" icon="el-icon-lock"></el-button>
            <el-button v-else type="warning" @click="unlockEvent(scope.row.id)" icon="el-icon-unlock"></el-button>
            <el-button type="danger" @click="deleteEvent(scope.row.id)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <card style="text-align: center">
      <div class="row">
        <div class="col-sm-12">
          <input type="file" ref="csv_file" name="csv_file"/>
          <el-button type="primary" @click="uploadFile()" icon="">Upload Excel File</el-button>
          <el-button type="primary" @click="openModal('download')" icon="">Download Excel File</el-button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_text }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Select Event to download"
      :visible.sync="modals.download">
      <div class="text-center">
        <el-select filterable v-model="selectedEvent" placeholder="Select Event" style="width: 50%">
          <el-option v-for="item in events"
                     :key="item.id" :value="item.id" :label="item.name">
          </el-option>
        </el-select>
        <br>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="downloadFile" icon="">Download File</el-button>
          <el-button type="danger" @click="closeModal('download')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.16.9/xlsx.full.min.js">
</script>
<script>
import { FileAPIService } from 'src/servicehandlers/FileHandler';
import { Dialog, Button, Input, Checkbox, Table, TableColumn, DatePicker, Select, Option, CheckboxButton, Switch } from 'element-ui';
// import { ModelSelect } from 'vue-search-select';
import moment from 'moment';
import {ref} from 'vue'
import * as XLSX from 'xlsx';
import EventsAPIService from "../../../../servicehandlers/EventsAPIService";
import EventTypesAPIService from "../../../../servicehandlers/EventTypesAPIService";
import UsersAPIService from "../../../../servicehandlers/UsersAPIService";
import OrderNumbersAPIService from "../../../../servicehandlers/OrderNumbersAPIService";
import BeltRanksAPIService from "../../../../servicehandlers/BeltRanksAPIService";
import RanksAPIService from "../../../../servicehandlers/RanksAPIService";

const eventsAPIService = new EventsAPIService();
const eventTypesAPIService = new EventTypesAPIService();
const usersAPIService = new UsersAPIService();
const fileAPI = new FileAPIService();
const orderNumbersAPIService = new OrderNumbersAPIService();
const beltRanksAPIService = new BeltRanksAPIService()
const ranksAPIService = new RanksAPIService()

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [CheckboxButton.name]: CheckboxButton,
    [Switch.name]: Switch,
  },
  name: "EventAdmin",
  data() {
    return {
      response_txt: null,
      error_txt: null,
      success_text: null,
      spinning: false,
      events: [],
      eventTypes: [],
      beltRanks: [],
      ranks: [],
      filteredEvents: [],
      users: [],
      modals: {
        error: false,
        success: false,
        download: false,
      },
      search: '',
      selectedEvent: null,
      showArchived: false,
    }
  },
  mounted() {
    this.getEvents();
    this.getEventTypes()
    this.getBeltRanks()
    this.getRanks()
  },
  methods: {
    lockEvent(event_id) {
      this.spinning = true;
      const event = {
        'locked': true,
      };
      return eventsAPIService.updateEvent(event_id, event, this.$router)
      .then(() => {
        this.spinning = false;
        this.getEvents();
      })
      .catch((error) => {
        this.error_txt = 'Error Updating Check Log: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    unlockEvent(event_id) {
      this.spinning = true;
      const event = {
        'locked': false,
      };
      return eventsAPIService.updateEvent(event_id, event, this.$router)
      .then(() => {
        this.spinning = false;
        this.getEvents();
      })
      .catch((error) => {
        this.error_txt = 'Error Updating Check Log: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    manageEvent(event) {
      this.$router.push({
        name: 'Event Manager',
        query: { event_id: event.id },
      });
    },
    getUsers() {
      this.spinning = true;
      return usersAPIService.getUserList(this.$router)
      .then((response) => {
        this.spinning = false;
        this.users = response;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting user list: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getEvents() {
      this.spinning = true;
      return eventsAPIService.getEvents(this.$router)
      .then((response) => {
        this.filteredEvents = []
        this.spinning = false;
        this.events = response;
        if (this.showArchived){
          this.filteredEvents = this.events
        } else {
          for (let event of this.events){
            if (!event.archived){
              this.filteredEvents.push(event)
            }
          }
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting events: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getEventTypes() {
      this.spinning = true;
      return eventTypesAPIService.getEventTypes(this.$router)
      .then((response) => {
        this.spinning = false;
        this.eventTypes = response;
        for (let type of this.eventTypes){
          type.value = false
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting event types: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getBeltRanks() {
      this.spinning = true;
      return beltRanksAPIService.getBeltRanks(this.$router)
      .then((response) => {
        this.spinning = false;
        this.beltRanks = response;
      })
      .catch((error) => {
        this.error_txt = 'Error getting belt ranks: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getRanks() {
      this.spinning = true;
      return ranksAPIService.getRanks(this.$router)
      .then((response) => {
        this.spinning = false;
        this.ranks = response;
      })
      .catch((error) => {
        this.error_txt = 'Error getting ranks: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    setItem(id, event) {
      this.spinning = true;
      return eventsAPIService.updateEvent(id, event, this.$router)
      .then(() => {
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error Updating Check Log: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.csv_file.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.csv_file.files) {
        formData.append('csv_file', fle);
      }
      fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        this.msg = '<span style="color: green">' + response.message + '</span>';
        this.successOrError = 'Success';
        this.successOrErrorMessage = 'File upload successful!';
        this.spinning = false;
        this.success_text = this.successOrErrorMessage;
        this.openModal('success');
      })
      .catch((error) => {
        this.msg = '<span style="color: red">' + error + '</span>';
        this.successOrError = 'Error';
        this.successOrErrorMessage = 'File upload error!: ' + error;
        this.spinning = false;
        this.error_txt = this.successOrErrorMessage;
        this.openModal('error');
      });
    },
    downloadFile(){
      orderNumbersAPIService.getAllOrdersByEvent(this.selectedEvent, this.$router)
        .then((response) => {

          const data = [];

          for (let line of response){
            let beltRankName = ''
            for (let rank of this.beltRanks){
              if (rank.id === line.user.belt_rank_id) beltRankName = rank.name
            }
            let rankName = ''
            let nextRankName = ''
            let nextRankBool = ''
            for (let rank of this.ranks){
              if (nextRankBool){
                nextRankName = rank.name
                break
              }
              if (rank.id === line.user.rank_id) {
                rankName = rank.name
                nextRankBool = true
              }
            }
            let temp = {
              first_name: line.user.first_name,
              last_name: line.user.last_name,
              event_id: this.selectedEvent,
              order_number: line.code,
              username: line.user.username,
              email: line.user.email,
              belt_rank_id: beltRankName,
              'Current Ranks': rankName,
              'Next Belt Rank': nextRankName,
              studio_name: line.user.studio_name,
              master_instructor_name: line.user.master_instructor_name,
              phone: line.user.phone,
              weight: line.user.weight,
            }
            data.push(temp)
          }
          let eventName = ''
          for (let event of this.events){
            if (event.id === this.selectedEvent){
              eventName = event.name
            }
          }
          let fileName = eventName + '.xlsx'

          const worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
          XLSX.writeFile(workbook, fileName);
          this.closeModal("download")
        })
        .catch((error) => {
          this.error_txt = "Error: " + error.message;
          this.openModal('error');
        });
    },
    deleteEvent(eventId) {
      if (!eventId) {
        // in case of a null event ID
        this.getEvents();
        return;
      }
      this.spinning = true;
      // only applies to a new item
      return eventsAPIService.deleteEvent(eventId, this.$router)
      .then((result) => {
        this.getEvents();
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error Saving New event: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    addNewEvent() {
      const event = {
        id: null,
        name: null,
        description: null,
        start_date: null,
        end_date: null,
      };
      this.events.push(event);
      this.filteredEvents = this.events
    },
    saveEvent(eventItem) {
      this.spinning = true;
      if (eventItem.id) {
        this.setItem(eventItem.id, eventItem);
      } else {
        // only applies to a new item
        return eventsAPIService.addEvent(eventItem, this.$router)
        .then((result) => {
          eventItem.id = result.id;
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error Saving New event: ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
    },
    async archiveEvent(event){
      event.archived = !event.archived
      await this.saveEvent(event)
      await this.getEvents()
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredEvents.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredEvents.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterEvents(id = 0){
      console.log(this.showArchived)
      if (this.showArchived){
        this.filteredEvents = this.events.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } else {
        this.filteredEvents = this.events.filter((e) => !e.archived).filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      }
      if (id === 0) {
        for (let type of this.eventTypes) {
          if (type.value) id = type.id
        }
      }
      if (id !== 0){
        this.filterEventsByTypes(id)
      }
    },
    filterEventsByTypes(id){
      let allFalse = true
      for (let type of this.eventTypes) {
        if (!type.value) {
          type.value = type.id === id;
        } else type.value = false
        if (type.value) allFalse = false
      }
      if (!allFalse) {
        let temp = []
        for (let event of this.filteredEvents) {
          if (event.event_type_id === id) {
              temp.push(event)
          }
        }
        this.filteredEvents = temp
      }
    },
  }
}
</script>

<style scoped>

</style>
