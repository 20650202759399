import CommonAPIService from './CommonHandler';

export class StepSparringAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getStepSparrings(router) {
    const url = '/api/stepsparrings';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addStepSparring(stepSparring, router) {
    const url = '/api/stepsparrings';
    return this.CommonAPIService.postCall(url, stepSparring, router);
  }

  updateStepSparring(stepSparringId, stepSparring, router) {
    const url = `/api/stepsparrings/${stepSparringId}`;
    return this.CommonAPIService.putCall(url, stepSparring, router);
  }

  deleteStepSparring(stepSparringId, router) {
    const url = `/api/stepsparrings/${stepSparringId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default StepSparringAPIService;
