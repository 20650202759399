<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Terminology Table</h4>
                <p class="card-category">
                  Create or Edit Terminology
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Terminology</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterTerms"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterTerms" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredTerms" style="width: 100%" height="65vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Name" v-model="scope.row.name" @change="updateTerminology(scope.row.id)"></el-input>
            <el-input placeholder="Name" v-model="scope.row.korean_name" @change="updateTerminology(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Description" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Description" v-model="scope.row.description" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" @change="updateTerminology(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="PDF" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.pdf">
              <el-button type="primary" @click="downloadPDF(scope.row.pdf, 'terminology', scope.row.id)" icon="">{{ scope.row.pdf }}</el-button>
              <el-button type="danger" @click="removeFile(scope.row.id)" icon="el-icon-delete"></el-button>
            </div>
            <div v-else>
              <el-button type="primary" @click="preUpload('pdf', scope.row.id)" icon="">Upload PDF</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="URL" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Media URL" v-model="scope.row.media" @change="updateTerminology(scope.row.id)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Tested" :min-width="150" align="center">
          <template v-slot="scope">
            <el-checkbox v-model="scope.row.tested" @change="updateTerminology(scope.row.id)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <el-button type="danger" @click="deleteTerminology(scope.row.id)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <el-button type="primary" @click="uploadFile" icon="">Upload</el-button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('pdf')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Terminology"
      :visible.sync="modals.create">
      <div>
        <div class="row align-items-center">
          <div class="col-sm-3">
            <label>Name:</label>
            <el-input placeholder="Name" v-model="name"></el-input>
          </div>
          <div class="col-sm-3">
            <label>Korean Name:</label>
            <el-input placeholder="Korean Name" v-model="korean_name"></el-input>
          </div>
          <div class="col-sm-3">
            <label>Description:</label>
            <el-input placeholder="Description" v-model="description"></el-input>
          </div>
          <div class="col-sm-3">
            <label>URL:</label>
            <el-input placeholder="Media URL" v-model="media"></el-input>
          </div>
          <div class="col-sm-12 text-center pt-2">
            <el-checkbox v-model="tested" size="large" border>Tested</el-checkbox>
          </div>
        </div>
        <br>
        <div slot="footer" class="dialog-footer text-center">
          <el-button type="primary" @click="createTerminology('create')" icon="">OK</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Select, Input, Button, Checkbox, Table, TableColumn } from 'element-ui';
import TerminologyAPIService from "src/servicehandlers/TerminologyAPIService";
import { FileAPIService } from 'src/servicehandlers/FileHandler';

const terminologyAPIService = new TerminologyAPIService();
const fileAPI = new FileAPIService();

export default {
  name: "Terminology",
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      spinning: false,
      selected_terminology_id: null,
      pdfFile: null,
      message: '',
      filename: null,
      name: null,
      description: null,
      tested: false,
      korean_name: null,
      pdf: null,
      media: null,
      last_order: null,
      terminologies: [],
      filteredTerms: [],
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        create: false,
        pdf: false,
      },
      search: '',
    }
  },
  mounted() {
    this.getTerminologies();
  },
  methods: {
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.filename;
        this.spinning = false;
        link.click();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Terminology PDF download failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    preUpload(name, id) {
      this.selected_terminology_id = id;
      this.openModal(name);
    },
    removeFile(id) {
      const parms = {
        pdf: null,
      };
      return terminologyAPIService.updateTerminology(id, parms, this.$router)
      .then(() => {
        this.getTerminologies();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'PDF clearing failure: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'terminology');
        formData.append('id', this.selected_terminology_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'PDF upload success';
        this.spinning = false;
        this.pdfFile = null;
        this.closeModal('pdf');
        this.openModal('success');
        this.getTerminologies();
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.terminologies.length; i += 1) {
        const id = this.terminologies[i].id;
        this.terminologies[i].order = i + 1;
        promises.push(terminologyAPIService.updateTerminology(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        this.getTerminologies();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateTerminology(id) {
      for (const term of this.terminologies) {
        if (id === term.id) {
          const parms = {
            name: term.name,
            description: term.description,
            media: term.media,
            tested: term.tested,
            korean_name: term.korean_name,
          };
          return terminologyAPIService.updateTerminology(id, parms, this.$router)
          .then(() => {
            const self = this;
            this.success_txt = 'Terminology update successful';
            /*this.openModal('success');
            setTimeout(function () {
              self.modals['success'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs*/
          })
          .catch((error) => {
            const self = this;
            this.error_txt = 'Terminology update failure: ' + error;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          });
        }
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deleteTerminology(id) {
      return terminologyAPIService.deleteTerminology(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Terminology successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getTerminologies();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Terminology: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getTerminologies() {
      return terminologyAPIService.getTerminology(this.$router)
      .then((response) => {
        for (const term of response) {
          this.last_order = term.order;
        }
        this.terminologies = response;
        this.filteredTerms = this.terminologies
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Terminologies: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createTerminology() {
      this.closeModal('create');
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      const terminology = {
        order,
        name: this.name,
        description: this.description,
        media: this.media,
        tested: this.tested,
        korean_name: this.korean_name
      };
      return terminologyAPIService.addTerminology(terminology, this.$router)
      .then((response) => {
        this.terminologies.push(response);
        this.name = null;
        this.description = null;
        this.media = null;
        this.tested = false;
        this.korean_name = null;
        const self = this;
        this.success_txt = 'Add Terminology successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error Creating Terminology: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.terminologies[index];
        this.terminologies.splice(index, 1);
        this.terminologies.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.terminologies.length - 1) {
        const temp = this.terminologies[index];
        this.terminologies.splice(index, 1);
        this.terminologies.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.terminologies.length; i++){
        this.terminologies[i].order = i+1
      }
      this.updateTerminology(item.id)
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredTerms.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredTerms.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterTerms(){
      this.filteredTerms = this.terminologies.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  }
}
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
</style>
