<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Kick Combos</h4>
                <p class="card-category">Create or Edit all Kick Combos</p>
                <p class="card-category">
                  <el-button type="primary" @click="createKick" icon="">Add New Kick Combo</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterKickCombos"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterKickCombos" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive table-full-width">
        <el-table stripe :data="filteredKickCombos" style="width: 100%" height="65vh" @sort-change="handleSortChange">
          <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
            <template v-slot="scope">
              <div>
                <div class="row">
                  <div class="col-sm-4">
                    {{ scope.row.order }}
                  </div>
                  <div class="col-sm-4">
                    <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
                  </div>
                  <div class="col-sm-4">
                    <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
            <template v-slot="{ row }">
              <el-input placeholder="Name" v-model="row.name" disabled></el-input>
              <el-input placeholder="Abbreviation" v-model="row.abbreviation" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Description" :min-width="200" align="center">
            <template v-slot="{ row }">
              <el-input placeholder="Description" v-model="row.description" disabled></el-input>
              <el-input placeholder="Notes" v-model="row.notes" @change="updateKick(row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="PDF" :min-width="150" align="center">
            <template v-slot="scope">
              <div v-if="scope.row.pdf">
                <el-button type="primary" @click="downloadPDF(scope.row.pdf, 'kick_combos', scope.row.id)" icon="">{{ scope.row.pdf }}</el-button>
                <el-button type="danger" @click="removeFile(scope.row.id)" icon="el-icon-delete"></el-button>
              </div>
              <div v-else>
                <el-button type="primary" @click="preUpload('pdf', scope.row.id)" icon="">Upload PDF</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="URL" :min-width="150" align="center">
            <template v-slot="{ row }">
              <el-input placeholder="Media" v-model="row.media" @change="updateKick(row)"></el-input>
            </template>
          </el-table-column >
          <el-table-column label="Kicks/Motions" :min-width="550" align="center">
            <template v-slot="{ row }">
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <el-select filterable v-model="selectedKick[row.id]" placeholder="Kick" style="width: 100%" clearable>
                    <el-option v-for="kick in kicks" v-bind:key="kick.id"
                               :value="kick" :label="kick.name">
                    </el-option>
                  </el-select>
                  <el-select filterable v-model="selectedMotion[row.id]" placeholder="Motion" style="width: 100%" clearable>
                    <el-option v-for="motion in motions" v-bind:key="motion.id"
                               :value="motion" :label="motion.name">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-sm-4">
                  <el-button type="success" @click="addItemToKickCombo('kick', row.id)" icon="">+ Kick</el-button>
                  <br>
                  <el-button type="success" @click="addItemToKickCombo('motion', row.id, selectedMotion[row.id])" icon="">+ Motion</el-button>
                </div>
                <div class="col-sm-4">
                  <el-button type="primary" @click="viewKickMotions(row)" icon="">View Kick/Motions</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Tested" :min-width="150" align="center">
            <template v-slot="{ row }">
              <el-checkbox v-model="row.tested" @change="updateKick(row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template v-slot="{ row }">
              <el-button type="danger" @click="removeKickCombo(row.id)" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <el-button type="primary" @click="uploadFile" icon="">Upload</el-button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('pdf')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Kicks/Motions"
      :visible.sync="modals.view">
      <div class="text-center">
        <div class="row">
          <el-table stripe :data="kicksToView.items" style="width: 100%" v-if="kicksToView">
            <el-table-column label="Name" :min-width="100" align="center">
              <template v-slot="scope">
                {{scope.row.name}}
              </template>
            </el-table-column>
            <el-table-column :min-width="100" align="center">
              <template v-slot="scope">
                <el-button type="danger" @click="removeItemFromKickCombo(kicksToView.id, scope.row, kicksToView)" icon="el-icon-delete"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('view')" icon="">Close</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn, Dialog, Select, Input, Checkbox, Option, Button } from 'element-ui';
import { FileAPIService } from 'src/servicehandlers/FileHandler';
import KickCombosAPIService from "src/servicehandlers/KickCombosAPIService";
import KicksAPIService from "src/servicehandlers/KicksAPIService";
import TechniquesAPIService from "../../../../servicehandlers/TechniquesAPIService";

const kickCombosAPIService = new KickCombosAPIService();
const kicksAPIService = new KicksAPIService();
const techniquesAPIService = new TechniquesAPIService();
const fileAPI = new FileAPIService();
import NotificationSample from '../../Components/NotificationSample';
import AddedKicksMotions from "./Notifications/AddedKicksMotions.vue";

export default {
  name: "KickCombos",
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Option.name]: Option,
    [Button.name]: Button,
  },
  data() {
    return {
      spinning: false,
      selected_kick_id: null,
      pdfFile: null,
      message: '',
      filename: null,
      name: null,
      description: null,
      last_kick_or_motion_order: 1,
      pdf: null,
      media: null,
      tested: null,
      last_order: null,
      selectedItem: '',
      kicks: [],
      motions: [],
      selectedKick: {},
      selectedMotion: {},
      newKickComboName: '',
      newKickComboDesc: '',
      kickCombos: [],
      filteredKickCombos: [],
      error_txt: null,
      success_txt: null,
      modals: {
        error: false,
        success: false,
        create: false,
        pdf: false,
        view: false,
      },
      search: '',
      kicksToView: null,
      technique_types: [],
    }
  },
  async mounted() {
    await this.getKicks();
    this.kicksToView = this.filteredKickCombos[0]
    this.getTechniqueTypes()
    await this.getMotions();
    await this.getKickCombos();
  },
  methods: {
    removeKickCombo(index) {
      return kickCombosAPIService.deleteKickCombos(index, this.$router)
      .then((kickdel) => {
        const self = this;
        this.success_txt = 'success deleting kick combo!';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getKickCombos();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting kick combo! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getItemById(array, id) {
      return array.find(item => item.id === id);
    },
    async addItemToKickCombo(type, index) {
      if (type === 'kick') {
        const kicks = this.selectedKick[index];
        if (kicks) {
          const kickCombo = this.getItemById(this.kickCombos, index);
          kickCombo.items.push(kicks);
          if (!kicks.id) {
            const self = this;
            this.error_txt = 'Missing Kick ID! ';
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
          const parms = {
            type: 'kick',
            order: this.last_kick_or_motion_order,
            kick_id: kicks.id,
          };
          let added = null;
          try {
            added = await kickCombosAPIService.addKickMotion(kickCombo.id, parms, this.$router);
            if (kickCombo.name) {
              kickCombo.name += ', ' + kicks.name;
            } else {
              kickCombo.name = kicks.name;
            }
            if (kickCombo.description) {
              kickCombo.description += ', ' + kicks.description;
            } else {
              kickCombo.description = kicks.description;
            }
            if (kickCombo.abbreviation) {
              kickCombo.abbreviation += ', ' + kicks.abbreviation;
            } else {
              kickCombo.abbreviation = kicks.abbreviation;
            }
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to add kick! ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }

          // last step, update the current item we're working on with the name, description, etc
          let updated_item = null;
          try {
            const parms = {
              id: kickCombo.id,
              abbreviation: kickCombo.abbreviation,
              description: kickCombo.description,
              name: kickCombo.name,
            }
            updated_item = await this.updateKick(parms);
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to update kick combo! ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
        }
      } else if (type === 'motion') {
        const motions = this.selectedMotion[index];
        if (motions) {
          const kickCombo = this.getItemById(this.kickCombos, index);
          kickCombo.items.push(motions);
          if (!motions.id) {
            const self = this;
            this.error_txt = 'Missing motion ID! ';
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
          const parms = {
            type: 'motion',
            order: this.last_kick_or_motion_order,
            motion_id: motions.id,
          };
          let added = null;
          try {
            added = await kickCombosAPIService.addKickMotion(kickCombo.id, parms, this.$router);
            if (kickCombo.name) {
              kickCombo.name += ', ' + motions.name;
            } else {
              kickCombo.name = motions.name;
            }
            if (kickCombo.description) {
              kickCombo.description += ', ' + motions.description;
            } else {
              kickCombo.description = motions.description;
            }
            if (kickCombo.abbreviation) {
              kickCombo.abbreviation += ', ' + motions.abbreviation;
            } else {
              kickCombo.abbreviation = motions.abbreviation;
            }
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to add motion! ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }

          // last step, update the current item we're working on with the name, description, etc
          let updated_item = null;
          try {
            const parms = {
              id: kickCombo.id,
              abbreviation: kickCombo.abbreviation,
              description: kickCombo.description,
              name: kickCombo.name,
            }
            updated_item = await this.updateKick(parms);
          } catch (e) {
            const self = this;
            this.error_txt = 'Failed to update kick combo! ' + e;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          }
        }
      }

      this.$notify({
        component: AddedKicksMotions,
        icon: 'nc-icon nc-app',
      })
      const kickCombo = this.getItemById(this.kickCombos, index)
      this.updateKick(kickCombo)
      this.last_kick_or_motion_order++;
    },
    async removeItemFromKickCombo(kickComboId, item, row) {
      // Find the index of the kickCombo based on its ID
      const kickComboIndex = this.kickCombos.findIndex(kickCombo => kickCombo.id === kickComboId);

      if (kickComboIndex !== -1) {
        const index = this.kickCombos[kickComboIndex].items.findIndex(
          foundItem => foundItem.type === item.type && foundItem.value === item.value
        );

        if (index !== -1) {
          if (item.type === 'kick') {

            let added = null;
            try {
              added = await kickCombosAPIService.removeKickMotion(kickComboId, 'kick', item.id, this.$router);

            } catch (e) {
              const self = this;
              this.error_txt = 'Failed to remove kick! ' + e;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            }
          } else if (item.type === 'motion') {
            let added = null;
            try {
              added = await kickCombosAPIService.removeKickMotion(kickComboId, 'motion', item.id, this.$router);
            } catch (e) {
              const self = this;
              this.error_txt = 'Failed to add motion! ' + e;
              this.openModal('error');
              this.spinning = false;
              setTimeout(function () {
                self.modals['error'] = false; // Use your variable name
              }, 1500); // Hide after 5 secs
            }
          }

          this.kickCombos[kickComboIndex].items.splice(index, 1);
          this.kickCombos[kickComboIndex].name = '';
          for (const item of this.kickCombos[kickComboIndex].items) {
            this.kickCombos[kickComboIndex].name += item.description + ', ';
          }
          this.kickCombos[kickComboIndex].name = this.kickCombos[kickComboIndex].name.replace(/, $/, '');

          this.kickCombos[kickComboIndex].description = '';
          for (const item of this.kickCombos[kickComboIndex].items) {
            this.kickCombos[kickComboIndex].description += item.description + ', ';
          }
          this.kickCombos[kickComboIndex].description = this.kickCombos[kickComboIndex].description.replace(/, $/, '');

          this.kickCombos[kickComboIndex].abbreviation = '';
          for (const item of this.kickCombos[kickComboIndex].items) {
            this.kickCombos[kickComboIndex].abbreviation += item.abbreviation + ', ';
          }
          this.kickCombos[kickComboIndex].abbreviation = this.kickCombos[kickComboIndex].abbreviation.replace(/, $/, '');
        } else {
          const self = this;
          this.error_txt = 'Item not found in kickcombos ';
          this.openModal('error');
          this.spinning = false;
          setTimeout(function () {
            self.modals['error'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs
        }
      } else {
        const self = this;
        this.error_txt = 'KickCombo with the provided ID not found ';
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      }

      this.updateKick(row)
    },
    getKicks() {
      return kicksAPIService.getKick(this.$router)
      .then((response) => {
        this.kicks = [];
        for (const item of response) {
          const kick = {
            text: item.description,
            value: item.id,
            description: item.description,
            name: item.name,
            id: item.id,
            abbreviation: item.abbreviation,
            type: 'kick'
          };
          this.kicks.push(kick);
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get kicks! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getMotions() {
      return techniquesAPIService.getTechnique(this.$router)
      .then((response) => {
        this.motions = [];
        let motionId
        for (let type of this.technique_types){
          if (type.name === 'Motion'){
            motionId = type.id
            break
          }
        }
        for (const item of response) {
          if (item.technique_type_id === motionId) {
            const motion = {
              text: item.description,
              description: item.description,
              name: item.name,
              value: item.id,
              id: item.id,
              abbreviation: item.abbreviation,
              type: 'motion'
            };
            this.motions.push(motion);
          }
        }
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Failed to get motions! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getTechniqueTypes() {
      return techniquesAPIService.getTechniqueTypes(this.$router)
        .then((response) => {
          this.technique_types = []
          for (const resp of response) {
            resp.value = resp.id;
            resp.text = resp.name;
            this.technique_types.push(resp);
          }
          this.technique_types.sort((a, b) => a.id - b.id);
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: 'Error getting technique types! ' + error,
            timer: 1000,
            confirmButtonClass: 'btn btn-danger btn-fill',
            showConfirmButton: false
          });
        });
    },
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.filename;
        this.spinning = false;
        link.click();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Kick Combo PDF download failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    preUpload(name, id) {
      this.selected_kick_id = id;
      this.openModal(name);
    },
    removeFile(id) {
      const parms = {
        pdf: null,
      };
      return kickCombosAPIService.updateKickCombos(id, parms, this.$router)
      .then(() => {
        this.getKickCombos();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'PDF clearing failure: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'kick_combos');
        formData.append('id', this.selected_kick_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'PDF upload success';
        this.spinning = false;
        this.pdfFile = null;
        this.closeModal('pdf');
        this.openModal('success');
        this.getKickCombos();
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.kickCombos.length; i += 1) {
        const id = this.kickCombos[i].id;
        this.kickCombos[i].order = i + 1;
        promises.push(kickCombosAPIService.updateKickCombos(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        this.getKickCombos();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updateKick(kick) {
          return kickCombosAPIService.updateKickCombos(kick.id, kick, this.$router)
          .then(() => {
            const self = this;
            this.success_txt = 'Kick Combos update successful';
            /*this.openModal('success');
            setTimeout(function () {
              self.modals['success'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs*/
          })
          .catch((error) => {
            const self = this;
            this.error_txt = 'Kick Combo update failure: ' + error;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
      if (name === 'view') this.kicksToView = null
    },
    deleteKick(id) {
      return kickCombosAPIService.deleteKickCombos(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Kick Combos successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getKickCombos();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Kicks: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getKickCombos() {
      this.kickCombos = [];
      return kickCombosAPIService.getKickCombos(this.$router)
      .then((response) => {
        for (const kick of response) {
          this.last_order = kick.order;
          kick.items = [];
          // add the items of the kicks if they exist too
          for (const item of kick.kicks_and_motions_to_kick_combos) {
            // loop through the kicks and motions, find each one as we go in the tables
            if (item && item.kick_id) {
              // it's a kick
              const kick_item = this.getItemById(this.kicks, item.kick_id);
              kick_item.value = kick_item.id;
              kick_item.text = kick_item.description;
              kick.items.push(kick_item);
              this.last_kick_or_motion_order = item.order
            } /*else if (item && item.motion_id) {
              // it's a kick
              const motion_item = this.getItemById(this.motions, item.motion_id);
              motion_item.value = motion_item.motion_id;
              motion_item.text = motion_item.description;
              kick.items.push(motion_item);
              this.last_kick_or_motion_order = item.order
            }*/
          }
          this.last_kick_or_motion_order++;
          this.kickCombos.push(kick);
        }
        this.filteredKickCombos = this.kickCombos
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Kick Combos: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createKick() {
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      const kick = {
        order,
        name: null,
        description: null,
        media: null,
      };
      return kickCombosAPIService.addKickCombos(kick, this.$router)
      .then((response) => {
        if (!response.items) {
          response.items = [];
          response.kicks_and_motions_to_kick_combos = [];
        }
        this.kickCombos.push(response);
        this.name = null;
        this.description = null;
        this.media = null;
        const self = this;
        this.success_txt = 'Add Kick combo successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error Creating Kick combo: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.kickCombos[index];
        this.kickCombos.splice(index, 1);
        this.kickCombos.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.kickCombos.length - 1) {
        const temp = this.kickCombos[index];
        this.kickCombos.splice(index, 1);
        this.kickCombos.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.kickCombos.length; i++){
        this.kickCombos[i].order = i+1
      }
      this.updateKick(item)
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredKickCombos.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredKickCombos.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterKickCombos(){
      this.filteredKickCombos = this.kickCombos.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    viewKickMotions(row){
      this.kicksToView = row
      this.openModal('view')
    },
  }
}
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
</style>
