import axios from 'axios';

export class CommonAPIService {
  constructor() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.loggedIn = false;
  }

  newFileCall(url, files, router) {
    const fullUrl = this.api_url + url;
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    return axios({
      method: 'get',
      url: fullUrl,
      responseType: 'blob',
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        router.push({
          name: 'Login',
        });
      }
    });
  }

  // main login method for the code
  login(login, router, store, errors) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    return axios.post(`${this.api_url}/api/auth/login`, login)
      .then((response) => {
        localStorage.setItem('jwtToken' + '', response.data.token);
        store.dispatch('ADD_USERID', response.data.user.id);
        store.dispatch('ADD_USERNAME', response.data.user.username);
        store.dispatch('ADD_USER_GROUPS', response.data.user.auth_groups);
        store.dispatch('SET_LOGGED_IN', true);
        store.dispatch('SET_PROXY_STATE', false)
        store.dispatch('ADD_PROXY_USERID', 0)
        store.dispatch('ADD_RANK_ASSIGNMENTS_DATA', {})
        return response.data;
      })
      .catch((e) => {
        errors.push(e);
        if (e.response.status === 401) {
          router.push({
            name: 'login',
          });
        }
        return {success: false, msg: 'Login Failure'};
      });
  }

  proxylogin(userID, originalID, leaveProxy, router, store, errors) {
    if (leaveProxy){
      userID = originalID
    }
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    return axios.post(`${this.api_url}/api/auth/${userID}/proxylogin`)
      .then((response) => {
        localStorage.setItem('jwtToken' + '', response.data.token);
        if (!leaveProxy){
          store.dispatch('ADD_PROXY_USERID', originalID)
        } else {
          store.dispatch('ADD_PROXY_USERID', 0)
        }
        store.dispatch('SET_PROXY_STATE', !leaveProxy)
        store.dispatch('ADD_USERID', response.data.user.id);
        store.dispatch('ADD_USERNAME', response.data.user.username);
        store.dispatch('ADD_USER_GROUPS', response.data.user.auth_groups);
        store.dispatch('SET_LOGGED_IN', true);
        return response.data;
      })
      .catch((e) => {
        errors.push(e);
        return {success: false, msg: 'Login Failure' + e};
      });
  }

  postCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, parameters)
      .then((response) => {
        // console.debug('postCall response', response);
        return response.data
      })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'login',
          });
        }
        throw error
      });
  }

  fileCall(url, files, router) {
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, files,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': localStorage.getItem('jwtToken'),
        }
      })
      .then((response) => {
      return response
      // return response.data
    })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'login',
          });
        }
        throw error
      });
  }

  deleteCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.delete(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
      });
  }

  putCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.put(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        throw error;
      });
  }

  getCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.get(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        // throw error
      });
  }
}

export default CommonAPIService;
